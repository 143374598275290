import React from "react";
import { BrowserRouter, Route } from "react-router-dom";

import Home from "../pages/Home";
import Reset from "../pages/Reset";

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Route path="/" component={Home} exact />
      <Route path="/reset" component={Reset} />
    </BrowserRouter>
  );
};

export default Routes;
