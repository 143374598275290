import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #fff;
`;

export const ContainerPassword = styled.div`
  width: 500px;
  height: 350px;
  background-color: #6f3f5d;

  border-radius: 15px;

  display: flex;
  align-items: center;
  padding: 10px;
  flex-direction: column;
  box-shadow: 0 0 1em #6f3f5d;
`;

export const Avatar = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 35px;
`;

export const Text = styled.span`
  font-size: 15px;
  margin-top: 10px;
`;
