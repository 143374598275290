import React, { useCallback, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { FiLock } from "react-icons/fi";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import Input from "../../components/Input";
import Button from "../../components/Button";
import * as Yup from "yup";
import getValidationErrors from "../../utils/getValidationErrors";
import api from "../../services/api";

import { Container, ContainerPassword, Avatar, Text } from "./styles";
import logoImg from "../../assets/images/logo.png";
import { useToast } from "../../hooks/toast";

interface ResetPasswordFormData {
  password: string;
  confirmPassword: string;
}

const Reset: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [reset, setReset] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: ResetPasswordFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          password: Yup.string().min(6, "Mínimo 6 dígitos"),
          confirmPassword: Yup.string().oneOf(
            [Yup.ref("password"), undefined],
            "Senha incorreta"
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const token = location.search.replace("?token=", "");

        if (!token) {
          throw new Error();
        }

        setReset(true);

        await api.post("/users/reset", {
          password: data.password,
          id: token,
        });

        setReset(false);

        addToast({
          title: "Sucesso",
          description: "Senha alterada com sucesso",
          type: "success",
        });

        history.push("/");
      } catch (e) {
        setReset(false);

        if (e instanceof Yup.ValidationError) {
          const errors = getValidationErrors(e);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          title: "Erro",
          description: "Erro ao alterar sua senha",
          type: "error",
        });
      }
    },
    [location, history, addToast]
  );

  return (
    <Container>
      <ContainerPassword>
        <Avatar src={logoImg} />

        <Text>Por favor, informe sua nova senha</Text>

        <Form ref={formRef} onSubmit={handleSubmit} style={{ width: 350 }}>
          <Input
            name="password"
            type="password"
            icon={FiLock}
            placeholder="Senha"
          />
          <Input
            name="confirmPassword"
            type="password"
            icon={FiLock}
            placeholder="Confirme sua senha"
          />

          <Button type="submit" disabled={reset ? true : false}>
            {reset ? "Redefinindo" : "Redefinir senha"}
          </Button>
        </Form>
      </ContainerPassword>
    </Container>
  );
};

export default Reset;
